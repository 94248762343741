import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, Check, Briefcase, Users } from 'lucide-react';

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <header className="bg-white shadow-sm fixed w-full z-10">
        <nav className="container mx-auto px-6 py-3">
          <div className="flex justify-between items-center">
            <div className="text-2xl font-bold text-indigo-600">fetchConsultant</div>
            <div className="hidden md:flex space-x-4">
              <a href="#consultants" className="text-gray-600 hover:text-gray-900">For Consultants</a>
              <a href="#clients" className="text-gray-600 hover:text-gray-900">For Clients</a>
              <a href="#how-it-works" className="text-gray-600 hover:text-gray-900">How It Works</a>
            </div>
            <div>
              <a href="https  ://app.fetchconsultant.com/auth" className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-full transition duration-300">
                Sign Up
              </a>
            </div>
          </div>
        </nav>
      </header>

      <main className="pt-16">
        <section className="bg-indigo-700 text-white py-20">
          <div className="container mx-auto px-6 text-center">
            <h1 className="text-5xl font-bold mb-4">Find Your Perfect Match in Healthcare IT</h1>
            <p className="text-xl mb-8">Connecting top Epic consultants with exciting opportunities</p>
            <Link to="/app" className="bg-white text-indigo-700 font-bold py-3 px-8 rounded-full text-lg hover:bg-gray-100 transition duration-300">
              Get Started
            </Link>
            <div className="mt-12">
              <ChevronDown className="mx-auto animate-bounce" size={32} />
            </div>
          </div>
        </section>

        <section id="consultants" className="py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">For Consultants</h2>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <Briefcase className="text-indigo-600 mb-4" size={32} />
                <h3 className="text-xl font-semibold mb-2">Find Opportunities</h3>
                <p className="text-gray-600">Access a wide range of Epic consulting opportunities tailored to your skills and preferences.</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <Users className="text-indigo-600 mb-4" size={32} />
                <h3 className="text-xl font-semibold mb-2">Connect Directly</h3>
                <p className="text-gray-600">Engage directly with healthcare organizations, bypassing traditional staffing agencies.</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <Check className="text-indigo-600 mb-4" size={32} />
                <h3 className="text-xl font-semibold mb-2">Manage Your Career</h3>
                <p className="text-gray-600">Take control of your consulting career with our easy-to-use platform and tools.</p>
              </div>
            </div>
          </div>
        </section>

        <section id="clients" className="py-20 bg-gray-100">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">For Clients</h2>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <Users className="text-indigo-600 mb-4" size={32} />
                <h3 className="text-xl font-semibold mb-2">Access Top Talent</h3>
                <p className="text-gray-600">Connect with highly skilled Epic consultants to meet your project needs.</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <Briefcase className="text-indigo-600 mb-4" size={32} />
                <h3 className="text-xl font-semibold mb-2">Streamlined Hiring</h3>
                <p className="text-gray-600">Simplify your hiring process with our efficient platform and tools.</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <Check className="text-indigo-600 mb-4" size={32} />
                <h3 className="text-xl font-semibold mb-2">Cost-Effective</h3>
                <p className="text-gray-600">Reduce costs associated with traditional staffing agencies and recruitment processes.</p>
              </div>
            </div>
          </div>
        </section>

        <section id="how-it-works" className="py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">How It Works</h2>
            <div className="max-w-3xl mx-auto">
              <div className="flex items-center mb-6">
                <div className="bg-indigo-600 rounded-full p-3 text-white mr-4">1</div>
                <p className="text-lg"><span className="font-semibold">Sign Up:</span> Create your account as a consultant or client.</p>
              </div>
              <div className="flex items-center mb-6">
                <div className="bg-indigo-600 rounded-full p-3 text-white mr-4">2</div>
                <p className="text-lg"><span className="font-semibold">Complete Your Profile:</span> Add your skills, experience, and preferences.</p>
              </div>
              <div className="flex items-center mb-6">
                <div className="bg-indigo-600 rounded-full p-3 text-white mr-4">3</div>
                <p className="text-lg"><span className="font-semibold">Browse Opportunities:</span> Find the perfect match for your skills or project needs.</p>
              </div>
              <div className="flex items-center">
                <div className="bg-indigo-600 rounded-full p-3 text-white mr-4">4</div>
                <p className="text-lg"><span className="font-semibold">Connect and Collaborate:</span> Start working together on exciting healthcare IT projects.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-indigo-700 text-white py-20">
          <div className="container mx-auto px-6 text-center">
            <h2 className="text-3xl font-bold mb-4">Ready to Transform Healthcare IT Staffing?</h2>
            <p className="text-xl mb-8">Join fetchConsultant today and experience the future of Epic consulting.</p>
            <Link to="app.fetchconsultant.com/auth" className="bg-white text-indigo-700 font-bold py-3 px-8 rounded-full text-lg hover:bg-gray-100 transition duration-300">
              Get Started Now
            </Link>
          </div>
        </section>
      </main>

      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto px-6">
          <div className="flex flex-wrap justify-between">
            <div className="w-full md:w-1/4 mb-6 md:mb-0">
              <h3 className="text-lg font-semibold mb-2">fetchConsultant</h3>
              <p className="text-sm">Connecting Epic talent with healthcare opportunities.</p>
            </div>
            <div className="w-full md:w-1/4 mb-6 md:mb-0">
              <h3 className="text-lg font-semibold mb-2">Quick Links</h3>
              <ul className="text-sm">
                <li><a href="#consultants" className="hover:text-indigo-400">For Consultants</a></li>
                <li><a href="#clients" className="hover:text-indigo-400">For Clients</a></li>
                <li><a href="#how-it-works" className="hover:text-indigo-400">How It Works</a></li>
              </ul>
            </div>
            <div className="w-full md:w-1/4 mb-6 md:mb-0">
              <h3 className="text-lg font-semibold mb-2">Contact</h3>
              <p className="text-sm">Email: info@fetchconsultant.com</p>
              <p className="text-sm">Phone: (123) 456-7890</p>
            </div>
            <div className="w-full md:w-1/4">
              <h3 className="text-lg font-semibold mb-2">Follow Us</h3>
              <div className="flex space-x-4">
                {/* Add your social media icons here */}
              </div>
            </div>
          </div>
          <div className="border-t border-gray-700 mt-8 pt-8 text-sm text-center">
            <p>&copy; 2024 fetchConsultant. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;